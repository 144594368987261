/* eslint-disable no-useless-escape */

const data = `
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'><strong><u><span style='font-size:14px;font-family:"Arial",sans-serif;'>CONTRATO DE PRESTACI&Oacute;N DE SERVICIO DE&nbsp;</span></u></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'><strong><u><span style='font-size:14px;font-family:"Arial",sans-serif;'>TRANSPORTE TERRESTRE DE PERSONAL</span></u></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>Conste por el presente documento, el Contrato de Prestaci&oacute;n de Servicio de Transporte Terrestre de Personal que suscriben de una Parte, <strong>TRANSFHERT RENT S.A.C</strong>, identificada con RUC N&deg; 20511906700, domiciliada en Calle Zeus N&deg; 331 Mz. A1 Lt. 26 Urbanizaci&oacute;n El Olimpo Salamanca - Ate, Lima, debidamente representada por la Sra. Edgar Roy Ravines Gamarra&nbsp; &nbsp;, identificada con DNI N&deg; 20113326, cuyo poder se encuentra inscrito en la Partida N&deg; 11812243 del Registro de Personas Jur&iacute;dicas de la Oficina Registral de Lima, a quien en adelante se le denominar&aacute; <strong>EL TRANSPORTISTA</strong>; y de la otra Parte, <strong>CONFIPETROL ANDINA S.A.</strong>, identificada con RUC N&deg; 20357259976, domiciliada en Av. Santo Toribio 173, Dpto. 1002, Edificio Real Ocho, distrito de San Isidro, provincia y departamento de Lima, debidamente representada por su Gerente Legal, la Sra. Mar&iacute;a Paz Denegri Silva, identificada con DNI N&deg; 07865570, y la Sra. Denisse Valiente Panta, identificada con DNI N&deg; 03883096, ambas con poderes inscritos en la Partida N&deg; 12930309 del Registro de Personas Jur&iacute;dicas de Lima, a quien en adelante se le denominar&aacute; <strong>CONFIPETROL</strong>; en los t&eacute;rminos y condiciones siguientes:</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><u><span style='font-size:14px;font-family:"Arial",sans-serif;'>PRIMERA</span></u></strong><strong><span style='font-size:14px;font-family:"Arial",sans-serif;'>: ANTECEDENTES</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>CONFIPETROL es una persona jur&iacute;dica constituida bajo el r&eacute;gimen de la Ley General de Sociedades, que presta servicios de operaci&oacute;n y mantenimiento en el sector minero, petrolero, energ&eacute;tico, gas&iacute;fero e industrial. En vista de ello, con el fin de poder seguir ampliando sus operaciones, CONFIPETROL requiere los servicios especializados de EL TRANSPORTISTA.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>EL TRANSPORTISTA es una persona que se dedica principalmente a la prestaci&oacute;n de servicios empresariales de movilidad o traslado de personas.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><br></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><u><span style='font-size:14px;font-family:"Arial",sans-serif;'>SEGUNDA</span></u></strong><strong><span style='font-size:14px;font-family:"Arial",sans-serif;'>: OBJETO</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>2.1 Por el presente documento, CONFIPETROL contrata los servicios de EL TRANSPORTISTA, para que este &uacute;ltimo le brinde los servicios de transporte terrestre de personal de CONFIPETROL, en la oportunidad y tiempo que esta &uacute;ltima requiera.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>2.2 Se deja expresa constancia que los servicios a cargo de EL TRANSPORTISTA ser&aacute;n prestados &iacute;ntegramente con el o los veh&iacute;culos de su propiedad, descritos en el Anexo N&deg; 1. En el caso de que el veh&iacute;culo de EL TRANSPORTISTA estuviere en reparaci&oacute;n, deber&aacute; brindar una opci&oacute;n similar del veh&iacute;culo para mantener el servicio.&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;background:aqua;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>2.3 Asimismo, queda establecido que EL TRANSPORTISTA prestar&aacute; el servicio indicado en el presente contrato, de forma diligente y de buena fe, con los permisos y autorizaciones vigentes para la realizaci&oacute;n del servicio objeto del contrato. Del mismo modo, el servicio deber&aacute; ser prestado en concordancia con lo establecido en las &ldquo;Cl&aacute;usulas T&eacute;cnicas del Servicio de Transporte de Personal&rdquo;, adjunto al presente Contrato como Anexo N&deg; 2.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><u><span style='font-size:14px;font-family:"Arial",sans-serif;'>TERCERA</span></u></strong><strong><span style='font-size:14px;font-family:"Arial",sans-serif;'>: AUTONOM&Iacute;A</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>3.1 Para el cumplimiento de las prestaciones a cargo de EL TRANSPORTISTA, este actuar&aacute; bajo su criterio y con arreglo al contenido del presente contrato, sin estar subordinado CONFIPETROL.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>Sin perjuicio de ello, ambas Partes designan como sus respectivos representantes de la coordinaci&oacute;n de los servicios contratados a:</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>Por CONFIPETROL: El Sra. Lourdes Flores</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;El Sr. Gregorio Rocca</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;El Sra. Ana Quispe</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>Por EL TRANSPORTISTA: La Sr. Edgar Ravines Gamarra</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>3.2 EL TRANSPORTISTA se encuentra facultado a contar con colaboradores externos, cuyos servicios correr&aacute;n por su cuenta y cargo, no gener&aacute;ndose ning&uacute;n tipo de v&iacute;nculo contractual con CONFIPETROL.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>3.3 De acuerdo con el objeto y naturaleza del Contrato, queda establecido que las Partes no pactan ni forman ning&uacute;n tipo de sociedad, agencia, representaci&oacute;n, ni ninguna figura que los vincule societaria ni tributariamente.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><u><span style='font-size:14px;font-family:"Arial",sans-serif;'>CUARTA</span></u></strong><strong><span style='font-size:14px;font-family:"Arial",sans-serif;'>: PLAZO</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>El plazo de duraci&oacute;n del presente contrato rige desde el 01 de septiembre de 2022 hasta el 31 de agosto de 2024. Sin embargo, con una anticipaci&oacute;n de treinta (30) d&iacute;as antes de la finalizaci&oacute;n del presente Contrato, las Partes podr&aacute;n acordar su renovaci&oacute;n, poni&eacute;ndose de acuerdo en cuanto a los t&eacute;rminos del mismo.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><u><span style='font-size:14px;font-family:"Arial",sans-serif;'>QUINTA</span></u></strong><strong><span style='font-size:14px;font-family:"Arial",sans-serif;'>: DESCRIPCI&Oacute;N DEL SERVICIO</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>5.1 EL TRANSPORTISTA asegurar&aacute; el servicio de transporte terrestre de personal de CONFIPETROL, para el servicio brindado por este &uacute;ltimo en la Unidad Minera El Porvenir (de titularidad de Nexa Resources El Porvenir S.A.C.), ubicado en Cerro de Pasco.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>5.2 El servicio deber&aacute; realizarse de ida y vuelta, de la siguiente manera:</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>Ruta: Cerro de Pasco &ndash; El Porvenir &ndash; Cerro de Pasco</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:14px;font-family:"Arial",sans-serif;'>Un (1) Bus 50 pasajeros&nbsp;</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>Placa F7C-967 A&ntilde;o 2020</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>Ruta Ida Ma&ntilde;ana (Pasco-Porvenir): Grifo Primax (San Juan) &ndash; La doble &ndash; 28 comandancia &ndash; Gira y regresa x la Municipalidad de Yanacancha &ndash; Colegio Mar&iacute;a Parado de Bellido &ndash; 5 de enero &ndash; Av. Minero &ndash; Comedor Parasgsha.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>Ruta Vuelta Tarde (Porvenir-Pasco): Comedor Parasgsha - Av. Minero &ndash; Hospital (Casa Piedra) &ndash; 5 Esquinas &ndash; Calle Lima &ndash; San Juan de Milpo (Porvenir)</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>Horario ma&ntilde;ana:&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>5:00 am se recoge al personal para llevarlos al comedor a desayunar.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>5:50 am parte al Porvenir.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:14px;font-family:"Arial",sans-serif;'>Un (1) Bus 50 pasajeros&nbsp;</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>Placa F7C-954 A&ntilde;o 2020</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>Ruta Ida Ma&ntilde;ana (Pasco-Porvenir): Grifo Primax (San Juan) &ndash; La doble &ndash; 28 comandancia &ndash; Gira y regresa x la Municipalidad de Yanacancha &ndash; Colegio Mar&iacute;a Parado de Bellido &ndash; 5 de enero &ndash; Av. Minero &ndash; Comedor Parasgsha.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>Ruta Vuelta Tarde (Porvenir-Pasco): Comedor Parasgsha - Av. Minero &ndash; Hospital (Casa Piedra) &ndash; 5 Esquinas &ndash; Calle Lima &ndash; San Juan de Milpo (Porvenir)</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>Horario ma&ntilde;ana:&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>5:00 am se recoge al personal para llevarlos al comedor a desayunar.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>5:50 am parte al Porvenir.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:14px;font-family:"Arial",sans-serif;'>Un (1) Coaster 28 &nbsp; pasajeros&nbsp;</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>Placa BVX-563 A&ntilde;o 2022</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>Ruta Ida Ma&ntilde;ana (Pasco-Porvenir): Grifo Primax (San Juan) &ndash; La doble &ndash; 28 comandancia &ndash; Gira y regresa x la Municipalidad de Yanacancha &ndash; Colegio Mar&iacute;a Parado de Bellido &ndash; 5 de enero &ndash; Av. Minero &ndash; Comedor Parasgsha.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>Ruta Vuelta Tarde (Porvenir-Pasco): Comedor Parasgsha - Av. Minero &ndash; Hospital (Casa Piedra) &ndash; 5 Esquinas &ndash; Calle Lima &ndash; San Juan de Milpo (Porvenir)</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>Horario ma&ntilde;ana:&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>5:00 am se recoge al personal para llevarlos al comedor a desayunar.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>5:50 am parte al Porvenir.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'><span style="text-decoration:line-through;color:red;"><del cite="mailto:Quispe,%20Cecilia%20Almendra" datetime="2019-10-10T18:31">&nbsp;</del></span></span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>5.3 El servicio deber&aacute; efectuarse de acuerdo a lo indicado por CONFIPETROL. Cuando el caso lo requiera, se aumentar&aacute; o disminuir&aacute; el n&uacute;mero de veh&iacute;culos, pudiendo variar tambi&eacute;n el horario y las rutas, a requerimiento de CONFIPETROL. Dicha modificaci&oacute;n deber&aacute; darse por escrito mediante la suscripci&oacute;n de la adenda respectiva, firmada por ambas Partes.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><u><span style='font-size:14px;font-family:"Arial",sans-serif;'>SEXTA</span></u></strong><strong><span style='font-size:14px;font-family:"Arial",sans-serif;'>: CONTRAPRESTACI&Oacute;N</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>6.1 El monto total mensual estimado del presente contrato asciende a la suma de S/ 20,310.00 (VEINTE MIL TRESCIENTOS DIEZ CON 00/100 SOLES) m&aacute;s IGV, lo cual ser&aacute; el resultado mensual de la sumatoria del importe de vueltas de S/ 396.80 (TRESCIENTOS NOVENTA Y SEIS CON 80/100 SOLES) m&aacute;s IGV, obtenido de las valorizaciones mensuales presentadas finalizado el mes, de acuerdo a lo indicado en la Orden de Compra N&deg; 4500410673 suscrita entre las Partes (Anexo N&deg; 3). Dicho monto comprende el costo total e integral del servicio, seguros e impuestos; as&iacute; como todo gasto necesario, directo e indirecto, relacionado con la correcta ejecuci&oacute;n del servicio.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>6.2 La tarifa considerar&aacute; cada vuelta realizada. &nbsp;&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>6.3 La tarifa que se deber&aacute; tener en cuenta respecto a la variaci&oacute;n del servicio que se indica en el numeral 5.3 del presente documento, deber&aacute; ser acordada previamente entre las Partes, la misma que deber&aacute; plasmarse en documento escrito (Adenda), debidamente suscrito por ambas</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>Partes.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>6.4 La tarifa podr&aacute; ser modificada cuando el combustible registre una alza o baja del 5%.&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>La reformulaci&oacute;n de la tarifa se dar&aacute; con la siguiente formula:</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>Si (Precio Nuevo del combustible / Precio Pactado) &ndash; 1 es mayor que 5% o menor que 5%.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>Entonces: &nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>Precio de vuelta x % de Combustible en Estructura de costos x Margen: <strong><u>Variaci&oacute;n de tarifa</u></strong></span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>Finalmente: <strong><u>Precio de vuelta + Variaci&oacute;n de tarifa</u></strong></span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>El precio del combustible con el que se pacta este contrato es de S/18.90, seg&uacute;n carta de combustible de Estaci&oacute;n de Servicios Grifo JIM EIRL.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>6.5 La contraprestaci&oacute;n indicada en el presente numeral incluye y compensa a EL TRANSPORTISTA por todos los gastos, costos directos, indirectos, gastos generales, imprevistos, p&eacute;rdidas, impuestos, indemnizaciones, y todos aquellos conceptos que no est&eacute;n expresados en forma expl&iacute;cita en que sea necesario incurrir para lograr la ejecuci&oacute;n y conclusi&oacute;n de todos los servicios, de acuerdo al presente contrato.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>6.6 La tarifa ser&aacute; modificada cuando EL TRANSPORTISTA no cumpla en la prestaci&oacute;n de sus servicios de acuerdo a lo indicado en el presente Contrato.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><u><span style='font-size:14px;font-family:"Arial",sans-serif;'>S&Eacute;PTIMA</span></u></strong><strong><span style='font-size:14px;font-family:"Arial",sans-serif;'>: FORMA DE PAGO</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>7.1 EL TRANSPORTISTA deber&aacute; entregar a CONFIPETROL la valorizaci&oacute;n y documentaci&oacute;n de sustento por los servicios efectivamente prestados, el d&iacute;a h&aacute;bil siguiente de finalizado el servicio mensual.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>7.2 De no haber observaciones a la valorizaci&oacute;n presentada por EL TRANSPORTISTA, CONFIPETROL deber&aacute; hacer la devoluci&oacute;n del mismo, debidamente firmada, y entregar el certificado emitido por esta &uacute;ltima, en un plazo de<span style="text-decoration:underline;color:teal;"><ins cite="mailto:Quispe,%20Cecilia%20Almendra" datetime="2019-10-10T15:16">&nbsp;cinco</ins></span> <span style="text-decoration:underline;color:teal;"><ins cite="mailto:Quispe,%20Cecilia%20Almendra" datetime="2019-10-10T15:16">(</ins></span><span style="text-decoration:underline;color:teal;"><ins cite="mailto:Quispe,%20Cecilia%20Almendra" datetime="2019-10-10T15:15">5</ins></span><span style="text-decoration:underline;color:teal;"><ins cite="mailto:Quispe,%20Cecilia%20Almendra" datetime="2019-10-10T15:16">)</ins></span><span style="text-decoration:line-through;color:red;"><del cite="mailto:Quispe,%20Cecilia%20Almendra" datetime="2019-10-10T15:15">3</del></span> d&iacute;as h&aacute;biles.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>7.3 EL TRANSPORTISTA podr&aacute; presentar la factura correspondiente una vez recibido el certificado indicado en el p&aacute;rrafo anterior, en los d&iacute;as y horarios de atenci&oacute;n fijados por CONFIPETROL. Asimismo, EL TRANSPORTISTA presentar&aacute; la factura por el mes completo, con el certificado indicado anteriormente, el mismo que incluir&aacute; el I.G.V. Dicha factura ser&aacute; pagada a los 60 d&iacute;as de su recepci&oacute;n por parte de CONFIPETROL (se establece como d&iacute;as de pago, los d&iacute;as martes o jueves).</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>7.4 EL TRANSPORTISTA adem&aacute;s de presentar los documentos indicados en el numeral anterior, para efectos del pago, deber&aacute; presentar los siguientes documentos:</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<ul style="list-style-type: undefined;margin-left:4.199999999999999px;">
    <li><span style='font-family:"Arial",sans-serif;font-size:14px;'>Relaci&oacute;n de trabajadores que prestaron el servicio objeto del contrato, durante el mes objeto de pago.</span></li>
    <li><span style='font-family:"Arial",sans-serif;font-size:14px;'>Boletas de pago de los trabajadores de EL TRANSPORTISTA del mes anterior al facturado, firmadas y canceladas, que evidencien que EL TRANSPORTISTA ha realizado los pagos laborales correspondientes a sus trabajadores; y/o todo otro documento que acredite el cumplimiento de pagos sociolaborales y tributarios impuestos por ley.</span></li>
    <li><span style='font-family:"Arial",sans-serif;font-size:14px;'>Copia de pago de beneficios sociales, de los trabajadores que hubieran dejado de laborar para EL TRANSPORTISTA durante el mes objeto de pago, los mismos que deber&aacute;n encontrarse relacionados a los servicios objeto del contrato.</span></li>
    <li><span style='font-family:"Arial",sans-serif;font-size:14px;'>Programa de mantenimiento preventivo de los veh&iacute;culos utilizados para la prestaci&oacute;n del servicio.</span></li>
    <li><span style='font-family:"Arial",sans-serif;font-size:14px;'>Evidencia de seguros contratados y pagados por EL TRANSPORTISTA, respecto a sus trabajadores, as&iacute; como de los seguros indicados en el presente Contrato, obligatorios para la prestacion del servicio de transporte.</span></li>
    <li><span style='font-family:"Arial",sans-serif;font-size:14px;'>Licencias y permisos vigentes necesarios para su operatividad.</span></li>
</ul>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;background:aqua;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>7.5 Cabe precisar que CONFIPETROL tendr&aacute; (sin perjuicio de cualquier otro derecho, acci&oacute;n, potestad o atribuci&oacute;n prevista en la ley o en el presente Contrato), derecho a deducir y/o compensar todas aquellas obligaciones y/o desembolsos realizados por el mismo a favor de EL TRANSPORTISTA, cuando CONFIPETROL asuma cualquier monto dinerario solicitado por terceros acreedores y/o alguna Autoridad Administrativa, Arbitral, y/o Judicial de EL TRANSPORTISTA. CONFIPETROL podr&aacute; hacer efectiva dicha deducci&oacute;n y/o compensaci&oacute;n de cualquier deuda o suma de dinero que le adeude o pudiese adeudar a EL TRANSPORTISTA, relacionado al presente Contrato y cualquier otro documento que los vincule legal y comercialmente.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>Sin perjuicio de lo expuesto, EL TRANSPORTISTA faculta expresamente a CONFIPETROL para que de forma facultativa y a su voluntad, retenga parte o toda la contraprestaci&oacute;n que le corresponda por el servicio, en los casos siguientes:&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<div style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;'>
    <ol start="1" style="margin-bottom:0cm;list-style-type: lower-alpha;">
        <li style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Arial",sans-serif;font-size:14px;'>Cuando el comprobante de pago presentado no cumpla los requisitos establecidos en legislaci&oacute;n pertinente y/o en el Contrato.&nbsp;</span></li>
    </ol>
</div>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<div style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;'>
    <ol start="2" style="margin-bottom:0cm;list-style-type: lower-alpha;">
        <li style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Arial",sans-serif;font-size:14px;'>Cuando EL TRANSPORTISTA adeude a CONFIPETROL suma alguna por cualquier concepto vinculado al Contrato, Orden de Compra o a cualquier otro documento que vincule a ambas Partes.&nbsp;</span></li>
    </ol>
</div>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<div style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;'>
    <ol start="3" style="margin-bottom:0cm;list-style-type: lower-alpha;">
        <li style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Arial",sans-serif;font-size:14px;'>Cuando EL TRANSPORTISTA haya contratado con alg&uacute;n tercero para el debido cumplimiento del Contrato, y dicho proveedor/contratista haya solicitado expresamente la retenci&oacute;n del monto que se le adeuda como consecuencia de su v&iacute;nculo contractual con EL TRANSPORTISTA.</span></li>
    </ol>
</div>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<div style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;'>
    <ol start="4" style="margin-bottom:0cm;list-style-type: lower-alpha;">
        <li style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Arial",sans-serif;font-size:14px;'>Cuando EL TRANSPORTISTA &nbsp;haya omitido efectuar el pago de sus obligaciones tributarias, laborales y cualesquiera otras obligaciones a su cargo, hasta que EL TRANSPORTISTA presente los comprobantes de pago correspondientes debidamente cancelados.</span></li>
    </ol>
</div>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<div style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;'>
    <ol start="5" style="margin-bottom:0cm;list-style-type: lower-alpha;">
        <li style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Arial",sans-serif;font-size:14px;'>Cuando EL TRANSPORTISTA adeude a favor de CONFIPETROL cualquier monto en virtud a la relaci&oacute;n contractual existente entre ambas y/o vinculada con bienes y/o servicios que hayan sido suministrados por CONFIPETROL en favor de EL TRANSPORTISTA. Asimismo, CONFIPETROL podr&aacute; descontar cualquier suma de dinero que hubiera tenido que pagar en nombre de EL TRANSPORTISTA por cualquier concepto a su cargo.</span></li>
    </ol>
</div>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>Adem&aacute;s, CONFIPETROL se encontrar&aacute; facultado a realizar y/o efectuar el pago en nombre de EL&nbsp;</span><span style='font-size:14px;font-family:"Arial",sans-serif;'>TRANSPORTISTA</span><span style='font-size:14px;font-family:"Arial",sans-serif;'>, de cualquier monto dinerario solicitado por terceros acreedores y/o alguna Autoridad Administrativa, Arbitral y/o Judicial, con motivo o no de la presente Orden de Compra.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>Sin perjuicio de lo se&ntilde;alado en el presente Contrato, EL&nbsp;</span><span style='font-size:14px;font-family:"Arial",sans-serif;'>TRANSPORTISTA</span><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;conoce y acepta que la aprobaci&oacute;n de facturas o la no aplicaci&oacute;n de penalidades por parte de CONFIPETROL, no constituye renuncia a ning&uacute;n derecho o mecanismo de protecci&oacute;n o aseguramiento de CONFIPETROL; por lo que esta podr&aacute; hacer efectiva en cualquier momento, las acciones que el Contrato y las normas legales lo permitan.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><u><span style='font-size:14px;font-family:"Arial",sans-serif;'>OCTAVA</span></u></strong><strong><span style='font-size:14px;font-family:"Arial",sans-serif;'>: OBLIGACIONES DE EL TRANSPORTISTA</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>8.1 EL TRANSPORTISTA deber&aacute; suministrar veh&iacute;culos en buen estado de funcionamiento y limpieza, conforme a lo descrito en el Anexo N&deg; 1, previsto con todos los elementos necesarios ante cualquier emergencia, y conducidos por personal id&oacute;neo. Dichos conductores deber&aacute;n contar con las licencias de conducir profesionales de la categor&iacute;a correspondiente, quienes adem&aacute;s deber&aacute;n tener una experiencia m&iacute;nima conforme a requerimiento expreso de CONFIPETROL.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>8.2 El mantenimiento de los veh&iacute;culos deber&aacute;n brindarse de forma regular, debiendo EL TRANSPORTISTA pasar las inspecciones necesarias y requeridas. Asimismo, EL TRANSPORTISTA deber&aacute; mantener los registros de mantenimiento que garanticen el adecuado funcionamiento de los mismos, debidamente acreditado por el taller especializado que realiz&oacute; la labor, oblig&aacute;ndose al cumplimiento del mismo. Adem&aacute;s, presentar&aacute;n en archivo f&iacute;sico o digital la hoja de ruta en la que se consignar&aacute; el n&uacute;mero de la hoja, la placa del veh&iacute;culo, el nombre del conductor o conductores, y su n&uacute;mero de licencia de conducir, el origen y destino, la hora de salida y llegada, la modalidad del servicio, las jornadas de conducci&oacute;n, de cada uno de los conductores y cualquier otra incidencia que haya ocurrido durante el viaje.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>8.3 Los veh&iacute;culos y los conductores de los mismos, deber&aacute;n portar alg&uacute;n tipo de signo distintivo que los identifique con EL TRANSPORTISTA.&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>8.4 EL TRANSPORTISTA deber&aacute; mantener un n&uacute;mero razonable de veh&iacute;culos reserva, a fin de evitar discontinuidad en el servicio, ante la eventualidad de fallas y faltas de los mismos designados para el servicio objeto del contrato.&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>8.5 EL TRANSPORTISTA deber&aacute; hacer los cambios de los veh&iacute;culos cuando CONFIPETROL lo solicite, siempre y cuando medie comunicaci&oacute;n escrita de dicho cambio a EL TRANSPORTISTA, indicando el motivo de dicho cambio. Asimismo, CONFIPETROL se reserva el derecho de rechazar el uso de cualquier veh&iacute;culo que se encuentre con defectos y/o fallas manifiestas, debiendo EL TRANSPORTISTA proporcionar el reemplazo correspondiente, en el menor tiempo posible.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>8.6 EL TRANSPORTISTA deber&aacute; contratar y pagar los siguientes seguros:</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:14.2pt;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;text-indent:-7.1pt;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>- Seguro Obligatorio contra Accidentes de Tr&aacute;nsito, que cubra da&ntilde;os personales ocasionados al personal de CONFIPETROL durante la prestaci&oacute;n del servicio, debi&eacute;ndose mantener vigente durante todo el plazo del contrato.&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:14.2pt;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;text-indent:-7.1pt;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>- P&oacute;liza de Accidentes personales por pasajero</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:14.2pt;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;text-indent:-7.1pt;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>- P&oacute;liza de Seguro Vehicular Total</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:14.2pt;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;text-indent:-7.1pt;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>- Seguro Complementario de Trabajo de Riesgo (SCTR) del personal que ejecute el servicio de transporte.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:14.2pt;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;text-indent:-7.1pt;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>- Seguro de Vida Ley del personal que ejecute el servicio de transporte, cuando corresponda seg&uacute;n lo establecido por Ley.&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:21.3pt;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:21.3pt;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>Disposiciones Generales:</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:21.3pt;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:21.3pt;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>Tanto la p&oacute;liza de Responsabilidad Civil General, como la P&oacute;liza de Seguro Contrato Todo Riesgo (TREC) deber&aacute;n incluir las siguientes condiciones:</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:21.3pt;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<ul style="list-style-type: square;margin-left:11.3px;">
    <li><span style='font-family:"Arial",sans-serif;font-size:14px;'>Incluir a CONFIPETROL y/o su casa matriz y/o compa&ntilde;&iacute;as afiliadas y/o subsidiarias, sus agentes, funcionarios y empleados como asegurados adicionales.</span></li>
    <li><span style='font-family:"Arial",sans-serif;font-size:14px;'>CONFIPETROL y/o su casa matriz y/o compa&ntilde;&iacute;as afiliadas y/o asociadas y/o subsidiarias, sus agentes, funcionarios y empleados tendr&aacute;n la denominaci&oacute;n de terceros en caso de siniestro, de forma tal que las p&oacute;lizas cubran adecuadamente cualquier da&ntilde;o a sus propiedades o de terceros, y/o a su personal.</span></li>
</ul>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:21.3pt;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<div style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;'>
    <ul style="margin-bottom:0cm;list-style-type: square;margin-left:11.3px;">
        <li style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Arial",sans-serif;font-size:14px;'>Deber&aacute;n indicar que las coberturas son primarias con respecto a los intereses de CONFIPETROL, y que cualquier otro seguro mantenido por CONFIPETROL es en exceso y no recurrente.&nbsp;</span></li>
    </ul>
</div>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:21.3pt;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<div style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;'>
    <ul style="margin-bottom:0cm;list-style-type: square;margin-left:11.3px;">
        <li style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Arial",sans-serif;font-size:14px;'>El asegurador y/o compa&ntilde;&iacute;a de seguros, renuncian a su derecho de subrogaci&oacute;n contra CONFIPETROL y/o su casa matriz y/o compa&ntilde;&iacute;as afiliadas y/o asociadas y/o subsidiarias, sus agentes, funcionarios y empleados.</span></li>
    </ul>
</div>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:21.3pt;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:21.3pt;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>Todos y cada uno de los deducibles y el pago de las primas de seguros correspondientes a las p&oacute;lizas mencionadas ser&aacute;n asumidos por EL TRANSPORTISTA, y correr&aacute;n por cuenta y riesgo del mismo.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>8.7 Asumir y responder exclusivamente por el pago de remuneraciones, indemnizaciones, seguros, impuestos, contribuciones, aportaciones y dem&aacute;s beneficios que establezcan las leyes vigentes, por el personal a su cargo, conforme la normativa vigente. CONFIPETROL se exime de cualquier responsabilidad sobre el personal de EL TRANSPORTISTA, por cuanto no existe ning&uacute;n v&iacute;nculo laboral entre los trabajadores de EL TRANSPORTISTA y CONFIPETROL.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>8.8 EL TRANSPORTISTA no podr&aacute; transferir en forma parcial o total la prestaci&oacute;n del servicio objeto del contrato, quedando prohibido efectuar la cesi&oacute;n de posici&oacute;n contractual, dando lugar a la resoluci&oacute;n del presente documento.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>8.9 Utilizar en forma permanente equipos de comunicaci&oacute;n m&oacute;vil u otro, que permita la ubicaci&oacute;n oportuna de su personal, apoyo requerido en situaci&oacute;n de siniestro de sus unidades y/o traslado de personal al lugar(es) que se&ntilde;ale CONFIPETROL.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><u><span style='font-size:14px;font-family:"Arial",sans-serif;'>NOVENA</span></u></strong><strong><span style='font-size:14px;font-family:"Arial",sans-serif;'>: OBLIGACIONES DE CONFIPETROL</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>Abonar a EL TRANSPORTISTA los pagos mensuales indicados en la cl&aacute;usula sexta del presente documento, a la presentaci&oacute;n de la factura correspondiente, previa conformidad de los servicios brindados.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:57.25pt;font-size:13px;font-family:"Times New Roman",serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><u><span style='font-size:14px;font-family:"Arial",sans-serif;'>D&Eacute;CIMO</span></u></strong><strong><span style='font-size:14px;font-family:"Arial",sans-serif;'>: RESPONSABILIDAD, MULTAS Y/O PENALIDADES</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>10.1 EL TRANSPORTISTA ser&aacute; responsable por aquellos da&ntilde;os generados por causas imputables a esta, como consecuencia de la ejecuci&oacute;n del presente Contrato.&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>10.2 Del mismo modo, EL TRANSPORTISTA se har&aacute; responsable de la correcta ejecuci&oacute;n del Contrato, debiendo mantener indemne a CONFIPETROL contra toda p&eacute;rdida, da&ntilde;o y/o responsabilidad y/o reclamaci&oacute;n relacionada al objeto del presente Contrato. En aquellos casos que, por culpa de EL TRANSPORTISTA, se le aplique a CONFIPETROL alguna penalidad, sanci&oacute;n, multa y/o descuento, esta &uacute;ltima trasladar&aacute; la misma a EL TRANSPORTISTA quien se encontrar&aacute; obligada a asumir el monto imputado.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>10.4 Le ser&aacute;n de aplicaci&oacute;n a EL TRANSPORTISTA, las multas y/o penalidades establecidas en el Anexo 4 &ldquo;Penalidades y Sanciones&rdquo;, adjunto al presente Contrato.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>10.5 Cualquier incumplimiento de las obligaciones del presente Contrato, deber&aacute; ser descontado de la facturaci&oacute;n presentada a CONFIPETROL.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><u><span style='font-size:14px;font-family:"Arial",sans-serif;'>D&Eacute;CIMO PRIMERA</span></u></strong><strong><span style='font-size:14px;font-family:"Arial",sans-serif;'>: RESOLUCI&Oacute;N DEL CONTRATO</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>El presente contrato podr&aacute; resolverse con anterioridad a la fecha se&ntilde;alada, por las siguientes causales:</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>11.1 Por mutuo acuerdo de las Partes intervinientes. Este acuerdo deber&aacute; constar necesariamente por escrito y deber&aacute; encontrarse suscrito por ambas Partes.&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>11.2 Por causas imputables a EL TRANSPORTISTA, en donde CONFIPETROL tendr&aacute; la facultad de resolver de pleno derecho el presente contrato, bastando para ello cursar aviso previo &nbsp;a EL TRANSPORTISTA. Podr&aacute;n ser consideradas causas imputables a EL TRANSPORTISTA las siguientes, no encontr&aacute;ndose limitadas:</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<ol start="1" style="list-style-type: lower-alpha;">
    <li><span style='font-family:"Arial",sans-serif;font-size:14px;'>Cuando no cumpla con la cl&aacute;usula Quinta del presente documento, la misma que describe los servicios a ser prestados por EL TRANSPORTISTA.</span></li>
    <li><span style='font-family:"Arial",sans-serif;font-size:14px;'>Cuando no cuente con la capacidad econ&oacute;mica o t&eacute;cnica para continuar con la prestaci&oacute;n del servicio.</span></li>
    <li><span style='font-family:"Arial",sans-serif;font-size:14px;'>Cuando los veh&iacute;culos designados al servicio no corespondan a los indicados en el Anexo 1 del Contrato.</span></li>
    <li><span style='font-family:"Arial",sans-serif;font-size:14px;'>Por incumplimiento injustificado de las cl&aacute;usulas descritas en el presente contrato.</span></li>
</ol>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;line-height:115%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;line-height:115%;font-family:"Arial",sans-serif;'>11.3 Por causas imputables a CONFIPETROL, cuando esta no cumpla con efectuar el pago correspondiente al servicio prestado por EL TRANSPORTISTA, y dicho pago se encuentre vencido, debiendo para ello EL TRANSPORTISTA cursar aviso previo de dicha facultad a CONFIPETROL, con treinta (30) d&iacute;as de anticipaci&oacute;n a la fecha efectiva de resoluci&oacute;n. CONFIPETROL deber&aacute; dar respuesta a dicha comunicaci&oacute;n, dentro de los cinco (5) d&iacute;as h&aacute;biles siguientes de notificada. En caso no existiese respuesta por parte de CONFIPETROL, se entender&aacute; que la misma ha quedado consentida.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;line-height:115%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;line-height:115%;font-family:"Arial",sans-serif;'>11.4 Asimismo, CONFIPETROL podr&aacute; resolver de pleno derecho el presente contrato, por razones de caso fortuito y/o fuerza mayor, la misma que deber&aacute; ser invocada a EL TRANSPORTISTA mediante documento escrito.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>11.5 Sin perjuicio de lo indicado en los numerales anteriores, CONFIPETROL tendr&aacute; la faculta de resolver el presente contrato de forma unilateral, sin mediar causa alguna, siempre y cuando remita una comunicaci&oacute;n a EL TRANSPORTISTA con treinta (30) d&iacute;as de anticipaci&oacute;n a la fecha efectiva de resoluci&oacute;n del mismo.&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><u><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>D&Eacute;CIMO SEGUNDA</span></u></strong><strong><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>:</span></strong><strong><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;CONTRACARGOS</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>12.1 Ambas Partes considerar&aacute;n contracargos a aquel costo experimentado por CONFIPETROL e imputable a EL TRANSPORTISTA, correspondiente a la ejecuci&oacute;n por parte de CONFIPETROL de los servicios de responsabilidad de EL TRANSPORTISTA. Sin limitaci&oacute;n, y a modo de ejemplo, los contracargos podr&aacute;n resultar de:</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<ul style="list-style-type: undefined;margin-left:4.199999999999999px;">
    <li><span style='font-family:"Arial",sans-serif;font-size:14px;'>Servicios ejecutados por CONFIPETROL, a solicitud de EL TRANSPORTISTA, por los servicios &nbsp;objeto del presente contrato.</span></li>
    <li><span style='font-family:"Arial",sans-serif;font-size:14px;'>Costos experimentados por CONFIPETROL, como resultado del incumplimiento, omisi&oacute;n o negligencia de EL TRANSPORTISTA, de lo establecido en el presente contrato.</span></li>
</ul>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>12.2 Al identificar CONFIPETROL un Contracargo, real o previsto, &eacute;sta emitir&aacute; un Aviso de Contracargo a EL TRANSPORTISTA. Este aviso describir&aacute; el servicio a ser ejecutado bajo el contracargo, el per&iacute;odo de ejecuci&oacute;n, el costo a ser cargado por CONFIPETROL a EL TRANSPORTISTA por el contracargo, as&iacute; como otras condiciones.&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>12.3 Dentro de las 24 horas despu&eacute;s de enviado el Aviso de Contracargo a EL TRANSPORTISTA, &eacute;ste enviar&aacute; de vuelta a CONFIPETROL una copia firmada del mismo, indicando ya sea la aceptaci&oacute;n del contracargo o el acuerdo de realizaci&oacute;n inmediata de los servicios descritos en el Contracargo.&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>12.4 Asimismo, transcurrido el plazo para devolver el Aviso de Contracargo firmado, y de no recibir respuesta a dicho aviso, se entender&aacute; &eacute;ste aceptado t&aacute;citamente. Los costos del contracargo se cargar&aacute;n a la cuenta de EL TRANSPORTISTA, los mismos que ser&aacute;n descontados o facturados de la facturaci&oacute;n mensual emitida por EL TRANSPORTISTA.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>12.5 El costo del contracargo consistir&aacute; en el costo real m&aacute;s un porcentaje del 15% (quince por ciento) para cubrir gastos administrativos.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><u><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>D&Eacute;CIMO TERCERA</span></u></strong><strong><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>: SUBCONTRATACI&Oacute;N Y CESI&Oacute;N&nbsp;</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>&nbsp;</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>EL TRANSPORTISTA no subcontratar&aacute;, total o parcialmente, los Servicios contratados por el presente documento, sin el previo consentimiento escrito de CONFIPETROL, el mismo que podr&aacute; negarse por cualquier raz&oacute;n.&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>Asimismo, EL TRANSPORTISTA estar&aacute; prohibido de ceder el presente Contrato, incluyendo sus derechos adquiridos, sin contar con la autorizaci&oacute;n previa y por escrito de CONFIPETROL, la cual podr&aacute; ser negada por razones de cualquier &iacute;ndole. Cualquier cesi&oacute;n por parte de EL TRANSPORTISTA, sin contar con la autorizaci&oacute;n previa y por escrito de EL CLIENTE carecer&aacute; de valor.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><u><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>D&Eacute;CIMO CUARTA</span></u></strong><strong><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>: LICENCIAS, PERMISOS Y AUTORIZACIONES</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>EL TRANSPORTISTA cuenta con todos los permisos, autorizaciones y licencias necesarios para el ejercicio de sus labores, debiendo mantenerlos vigentes y/o renovarlos de ser el caso. Asimismo, EL TRANSPORTISTA deber&aacute; adjuntar copia de dichos permisos, autorizaciones y licencias en el Anexo N&deg; 5 del presente Contrato.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><u><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>D&Eacute;CIMO QUINTA</span></u></strong><strong><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>: CONFIDENCIALIDAD</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>Cada Parte conviene en observar, guardar y mantener de manera confidencial y en estricta reserva, toda la informaci&oacute;n y/o documentaci&oacute;n obtenida por cualquier medio o fuente derivado del presente Contrato, pudiendo estar la misma relacionada a los clientes, proveedores y/o cualquier otro tercero relacionado a cada una de las Partes. Dicha obligaci&oacute;n de confidencialidad, deber&aacute; ser cumplida inclusive finalizado el presente Contrato, de forma indefinida.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>La &uacute;nica excepci&oacute;n de cumplimiento del p&aacute;rrafo anterior, se deriva de mandato u orden judicial, debiendo la parte que sea requerida cumplir con dicho requerimiento.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><u><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>D&Eacute;CIMO SEXTA</span></u></strong><strong><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>: NATURALEZA</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>Queda claramente establecido que dada la naturaleza del v&iacute;nculo que origina este contrato, no existe relaci&oacute;n laboral alguna entre EL TRANSPORTISTA y CONFIPETROL, debiendo sujetarse a las normas que regulan las relaciones de naturaleza civil y comercial.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>En tal sentido, e</span><span style='font-size:14px;font-family:"Arial",sans-serif;'>ste Contrato se regir&aacute; y ser&aacute; interpretado de conformidad con las leyes de la Rep&uacute;blica del Per&uacute;. Para todo aquello no establecido en el presente documento, ser&aacute; de aplicaci&oacute;n supletoria lo se&ntilde;alado en el C&oacute;digo Civil Peruano.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><u><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>D&Eacute;CIMO SEPTIMA</span></u></strong><strong><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>: ANEXOS AL CONTRATO</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>&nbsp;</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>En adici&oacute;n a lo establecido en el presente Contrato, ambas Partes acuerdan dar obligatorio cumplimiento a lo se&ntilde;alado en los siguientes documentos, as&iacute; como sus respectivas actualizaciones, los mismos que formar&aacute;n parte inseparable del Contrato:</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:35.4pt;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;text-indent:-35.4pt;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>ANEXO N&deg; 1: Descripci&oacute;n y caracter&iacute;sticas de veh&iacute;culos objeto del Servicio, as&iacute; como de los Conductores a utilizar en el Servicio.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>ANEXO N&deg; 2: &ldquo;Cl&aacute;usulas T&eacute;cnicas del Servicio de Transporte de Personal&rdquo;,</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>ANEXO N&deg; 3: &ldquo;Orden de Compra&rdquo;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>ANEXO N&deg; 4: &ldquo;</span><span style='font-size:14px;font-family:"Arial",sans-serif;'>Penalidades</span><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;y&nbsp;</span><span style='font-size:14px;font-family:"Arial",sans-serif;'>Sanciones</span><span style='font-size:14px;font-family:"Arial",sans-serif;'>&rdquo;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>ANEXO N&deg; 5: &ldquo;Permisos y Autorizaciones&rdquo;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>ANEXO N&deg; 6: &ldquo;Cotizaci&oacute;n de EL TRANSPORTISTA&rdquo;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>ANEXO N&deg;7: Precio del combustible Pactado</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>ANEXO N&deg;8: Estructura de Costos</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>Cabe precisar que el orden de prelaci&oacute;n de los documentos antes indicados es como figura. De darse el caso, en que se encuentre en discrepancia alguno de los documentos anteriormente listados y el contrato, primar&aacute; lo establecido en el Contrato.&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><u><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>D&Eacute;CIMO OCTAVA</span></u></strong><strong><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>: JURISDICCION</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>Para todo lo relacionado a cualquier controversia, desavenencia, diferencia o reclamaci&oacute;n generada por la interpretaci&oacute;n, ejecuci&oacute;n y cumplimiento del presente contrato, las Partes se someten a los Jueces y Tribunales del Distrito Judicial de Lima.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>Se suscribe el presente documento en se&ntilde;al de conformidad, el 01 de septiembre de 2022.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>Por EL TRANSPORTISTA:&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Por CONFIPETROL:</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;__________________&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;__________________ &nbsp;__________________</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>TRANSFHERT RENT S.A.C&nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;&nbsp; &nbsp; Mar&iacute;a Paz Denegri&nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp;Denisse Valiente</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>&nbsp;</span></p>
<p><strong><u><span style='font-size:14px;line-height:115%;font-family:"Arial",sans-serif;'><br>&nbsp;</span></u></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;'><strong><u><span style='font-size:14px;line-height:115%;font-family:"Arial",sans-serif;'><span style="text-decoration:none;">&nbsp;</span></span></u></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'><strong><u><span style='font-size:14px;font-family:"Arial",sans-serif;'>ANEXO N&deg; 4</span></u></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'><strong><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'><strong><span style='font-size:14px;font-family:"Arial",sans-serif;'>PENALIDADES Y SANCIONES</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<div style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;'>
    <ol style="margin-bottom:0cm;list-style-type: upper-roman;margin-left:4.199999999999999px;">
        <li style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;'><u><span style='font-family:"Arial",sans-serif;font-size:14px;'>INDICADORES CLAVE DE DESEMPE&Ntilde;O (KPI)</span></u></li>
    </ol>
</div>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>El desempe&ntilde;o del <em>Contrato de Prestaci&oacute;n de Servicio de Transporte Terrestre&nbsp;</em>brindado por EL TRANSPORTISTA, ser&aacute; evaluado mediante el uso de indicadores claves de desempe&ntilde;o, KPI (Key Performance Index), seg&uacute;n la siguiente tabla:&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<table style="border-collapse:collapse;border:none;">
    <tbody>
        <tr>
            <td style="width: 27.9pt;border: 1pt solid windowtext;background: rgb(221, 217, 195);padding: 0cm 5.4pt;vertical-align: top;">
                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'><strong><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>N&deg;</span></strong></p>
            </td>
            <td style="width: 278.3pt;border-top: 1pt solid windowtext;border-right: 1pt solid windowtext;border-bottom: 1pt solid windowtext;border-image: initial;border-left: none;background: rgb(221, 217, 195);padding: 0cm 5.4pt;vertical-align: top;">
                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'><strong><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>Indicador y Descripci&oacute;n</span></strong></p>
            </td>
            <td style="width: 125.6pt;border-top: 1pt solid windowtext;border-right: 1pt solid windowtext;border-bottom: 1pt solid windowtext;border-image: initial;border-left: none;background: rgb(221, 217, 195);padding: 0cm 5.4pt;vertical-align: top;">
                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'><strong><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>Meta Nivel Aceptable</span></strong></p>
                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'><strong><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>(mensual)</span></strong></p>
            </td>
        </tr>
        <tr>
            <td style="width: 27.9pt;border-right: 1pt solid windowtext;border-bottom: 1pt solid windowtext;border-left: 1pt solid windowtext;border-image: initial;border-top: none;padding: 0cm 5.4pt;vertical-align: top;">
                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'><span style='font-size:14px;font-family:  "Arial",sans-serif;'>1</span></p>
            </td>
            <td style="width: 278.3pt;border-top: none;border-left: none;border-bottom: 1pt solid windowtext;border-right: 1pt solid windowtext;padding: 0cm 5.4pt;vertical-align: top;">
                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>Operatividad de la Unidad de Transporte</span></p>
            </td>
            <td style="width: 125.6pt;border-top: none;border-left: none;border-bottom: 1pt solid windowtext;border-right: 1pt solid windowtext;padding: 0cm 5.4pt;vertical-align: top;">
                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'><span style='font-size:14px;font-family:  "Arial",sans-serif;'>100%</span></p>
            </td>
        </tr>
        <tr>
            <td style="width: 27.9pt;border-right: 1pt solid windowtext;border-bottom: 1pt solid windowtext;border-left: 1pt solid windowtext;border-image: initial;border-top: none;padding: 0cm 5.4pt;vertical-align: top;">
                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'><span style='font-size:14px;font-family:  "Arial",sans-serif;'>2</span></p>
            </td>
            <td style="width: 278.3pt;border-top: none;border-left: none;border-bottom: 1pt solid windowtext;border-right: 1pt solid windowtext;padding: 0cm 5.4pt;vertical-align: top;">
                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>Disponibilidad de los Choferes</span></p>
            </td>
            <td style="width: 125.6pt;border-top: none;border-left: none;border-bottom: 1pt solid windowtext;border-right: 1pt solid windowtext;padding: 0cm 5.4pt;vertical-align: top;">
                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'><span style='font-size:14px;font-family:  "Arial",sans-serif;'>100%</span></p>
            </td>
        </tr>
        <tr>
            <td style="width: 27.9pt;border-right: 1pt solid windowtext;border-bottom: 1pt solid windowtext;border-left: 1pt solid windowtext;border-image: initial;border-top: none;padding: 0cm 5.4pt;vertical-align: top;">
                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'><span style='font-size:14px;font-family:  "Arial",sans-serif;'>3</span></p>
            </td>
            <td style="width: 278.3pt;border-top: none;border-left: none;border-bottom: 1pt solid windowtext;border-right: 1pt solid windowtext;padding: 0cm 5.4pt;vertical-align: top;">
                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>Accidentes de tr&aacute;nsito</span></p>
            </td>
            <td style="width: 125.6pt;border-top: none;border-left: none;border-bottom: 1pt solid windowtext;border-right: 1pt solid windowtext;padding: 0cm 5.4pt;vertical-align: top;">
                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'><span style='font-size:14px;font-family:  "Arial",sans-serif;'>0 (cero)</span></p>
            </td>
        </tr>
    </tbody>
</table>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>La evaluaci&oacute;n antes indicada, ser&aacute; medida mensualmente.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<div style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;'>
    <ol style="margin-bottom:0cm;list-style-type: undefined;margin-left:4.199999999999999px;">
        <li style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;'><u><span style='font-family:"Arial",sans-serif;font-size:14px;'>PENALIDADES</span></u></li>
    </ol>
</div>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>El incumplimiento de las metas comprometidas en el literal anterior, facultar&aacute; a CONFIPETROL a aplicar EL TRANSPORTISTA las siguientes penalidades: &nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<div style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;'>
    <ol style="margin-bottom:0cm;list-style-type: decimal;">
        <li style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Arial",sans-serif;font-size:14px;'>Penalidad equivalente al 5% de la facturaci&oacute;n mensual, por cada d&iacute;a en que la unidad de transporte no se encuentre operativa y/o no sea reemplazada inmmediatamente (sin afectar la prestaci&oacute;n del servicio objeto del Contrato) por una unidad de transporte de similares caracter&iacute;sitcas.</span></li>
    </ol>
</div>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:36.0pt;font-size:13px;font-family:"Times New Roman",serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<div style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;'>
    <ol style="margin-bottom:0cm;list-style-type: undefined;">
        <li style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Arial",sans-serif;font-size:14px;'>Penalidad equivalente al 5% de la facturaci&oacute;n mensual, por cada d&iacute;a en que el servicio objeto del contrato no cuente con la disponibilidad del chofer y/o no sea reemplazado inmediatamente (sin afectar la prestaci&oacute;n del servicio objeto del Contrato), por un chofer de iguales cualidades.</span></li>
    </ol>
</div>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>&nbsp;</span></p>
`;
export default data;
