<template>
	<div class="dataaa" id="editor-wrapper" :style="{ minHeight: '200px' }">
		<v-container>
			<div class="pa-4 white">
				<ckeditor
					:editor="editor"
					v-model="editorData"
					:config="editorConfig"
					@ready="onReady"
				></ckeditor>
			</div>
		</v-container>
	</div>
</template>
<script>
// import Vue from 'vue';
// import CKEditor from '@ckeditor/ckeditor5-vue2';
//import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

//import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
//import DecoupledDocument from '@ckeditor/ckeditor5-editor-decoupled/src/decouplededitor';
import DecoupledDocument from '@ckeditor/ckeditor5-build-decoupled-document';

// import GeneralHtmlSupport from '@ckeditor/ckeditor5-html-support/src/generalhtmlsupport';

// import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials';
// import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold';
// import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic';
// import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph';

import file from './template.js';
// Vue.use(CKEditor);

export default {
	name: 'CkeditorBox',
	data() {
		return {
			editor: DecoupledDocument,
			editorData: file,
			//plugins: [BoldPlugin]
			editorConfig: {
				// The configuration of the editor.
				language: 'es',
				// translationsOutputFile: /app/,
				// plugins: [
				// 	EssentialsPlugin,
				// 	BoldPlugin,
				// 	ItalicPlugin,
				// 	ParagraphPlugin,
				// ],
				// toolbar: {
				// 	items: ['bold', 'italic', 'undo', 'redo'],
				// },
			},
		};
	},
	methods: {
		onReady(editor) {
			// Insert the toolbar before the editable area.
			editor.ui
				.getEditableElement()
				.parentElement.insertBefore(
					editor.ui.view.toolbar.element,
					editor.ui.getEditableElement()
				);
		},
	},
	components: {},
};
</script>

<style lang="scss">
.v-application #editor-wrapper p {
	margin-bottom: initial;
}
</style>
